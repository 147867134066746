import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Text,
  VStack,
  Flex,
  Box,
  Image,
  Button,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import PageContainer from '../../../components/Container/PageContainer';
import DashedContainer from '../../../components/Container/DashedContainer';

// import BgHome from 'src/assets/bg-home.png';
import BgHome from 'src/assets/bg-home-new.webp';
import whatIsArcade from 'src/assets/oqueearcade-thumb.jpg';
import jogosImg from 'src/assets/jogos-thumb.jpg';
import cacacoresImg from 'src/assets/oqueearcade.jpg';
import players from 'src/assets/icons/players.svg';
import rooms from 'src/assets/icons/rooms.svg';
import trophy from 'src/assets/icons/trophy.svg';
import ImageBox from 'src/components/ImageBox/ImageBox';
import SelectTicketForm, {
  SelectTicketFormValues,
} from 'src/modules/Forms/SelectTicketForm';
import Section from 'src/components/Section';
import { useDispatch } from 'react-redux';
import { BookingActions } from 'src/redux/booking';
import {
  getBookingTypesByConfig,
  postReturnPrices,
} from 'src/api/services/booking';
import VideoBox from 'src/components/VideoBox';
import TikTokPixel from 'tiktok-pixel';
import usePHToast from 'src/hooks/useToast';
import { Loading } from 'src/components/Loading';
import { GetBookingTypesByConfigResult } from 'src/api/interfaces/booking';

interface PriceProps {
  img: any;
}

function ContentPrices({ img }: PriceProps) {
  const navigate = useNavigate();
  const toast = usePHToast();
  const [isLoading, setIsLoading] = useState(false);
  const [numberPeople, setNumberPeople] = useState(4);
  const [priceRoom, setPriceRoom] = useState<string>('R$ Calculando');
  const [subTotal, setsubTotal] = useState<string>('R$ Calculando');
  const [selectedBookingTypeID, setSelectedBookingTypeID] = useState<number>(5);
  const [colunar] = useMediaQuery('(max-width: 1200px)');
  const [loadingBookingTypes, setLoadingBookingTypes] =
    useState<boolean>(false);
  const [bookingTypes, setBookingTypes] =
    useState<GetBookingTypesByConfigResult[]>();

  const apiCallRoom = async () => {
    try {
      setIsLoading(true);

      const roomId = [1];
      const reqData = {
        roomIDs: roomId,
        duration: 2,
        tickets: numberPeople,
        businessUnitID: 1,
        date: '2023-06-29',
      };
      const req = await postReturnPrices(reqData);

      setPriceRoom(req.pricePerPerson);
      setsubTotal(req.subTotal);
    } catch (e) {
      return;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedBookingTypeID === 1) {
      if (numberPeople < 4) return setNumberPeople(4);
      apiCallRoom();
    } else {
      if (numberPeople > 6) return setNumberPeople(6);
      const price =
        bookingTypes?.find((bt) => bt.id === selectedBookingTypeID)?.price || 0;
      setPriceRoom('R$ ' + price);
      setsubTotal('R$ ' + price * numberPeople);
    }
  }, [numberPeople, selectedBookingTypeID, bookingTypes]);

  const handlePeopleUp = () => {
    setNumberPeople((prev) => {
      let newNumberPeople = prev;

      if (selectedBookingTypeID === 5 && prev < 6) newNumberPeople++;
      if (selectedBookingTypeID === 1 && prev < 12) newNumberPeople++;

      return newNumberPeople;
    });
  };

  const handlePeopleDown = () => {
    setNumberPeople((prev) => {
      let newNumberPeople = prev;

      if (selectedBookingTypeID === 5 && prev > 2) newNumberPeople--;
      if (selectedBookingTypeID === 1 && prev > 4) newNumberPeople--;

      return newNumberPeople;
    });
  };

  const getBookingTypes = () => {
    setLoadingBookingTypes(true);
    getBookingTypesByConfig({
      Tickets: 4,
      BusinessUnitID: 1,
      Date: '2023-06-29',
    })
      .then((res) => {
        setBookingTypes(res);
      })
      .catch((err) => {
        toast({ status: 'error', title: err.message });
      })
      .finally(() => setLoadingBookingTypes(false));
  };

  useEffect(() => {
    getBookingTypes();
  }, []);

  return (
    <DashedContainer
      gap={12}
      flexDir={colunar ? 'column' : 'row'}
      alignItems="normal"
    >
      <Box flex={1 / 2}>
        <Image
          w="100%"
          h="100%"
          src={
            'https://arcadehausstorage.blob.core.windows.net/placeholders/Simule-a-sua-reserva.webp'
          }
        />
      </Box>

      <Stack flex={1 / 2} gap={5}>
        <Stack>
          <Text variant="title" color="white">
            Selecione o tipo de experiência
          </Text>
          <Text variant="text">
            Grupos de 2 ou 3 jogadores podem adquirir Ingressos Avulsos e
            compartilhar a sala com outros participantes; grupos de 4 a 6
            escolhem entre Ingressos Avulsos ou Reserva de Sala; para grupos de
            7 a 12 jogadores, é necessário fazer uma Reserva de Sala.
          </Text>
          <HStack>
            <Box
              w="100%"
              onClick={() => setSelectedBookingTypeID(5)}
              cursor="pointer"
              padding={3}
              bg={selectedBookingTypeID === 5 ? 'green' : 'white'}
            >
              <Text
                fontWeight={selectedBookingTypeID === 5 ? 'bold' : 'normal'}
              >
                Ingresso Avulso
              </Text>
            </Box>
            <Box
              w="100%"
              onClick={() => setSelectedBookingTypeID(1)}
              cursor="pointer"
              padding={3}
              bg={selectedBookingTypeID === 5 ? 'white' : 'green'}
            >
              <Text
                fontWeight={selectedBookingTypeID === 5 ? 'normal' : 'bold'}
              >
                Reserva de Sala
              </Text>
            </Box>
          </HStack>
          <Text color="white">
            {selectedBookingTypeID === 1
              ? '*Caso haja disponibilidade, você pode reservar mais de 55 minutos.'
              : '*Para Ingressos Avulsos, só é possível reservar 55 minutos e você poderá compartilhar a sala com outros participantes.'}
          </Text>
        </Stack>

        <Stack>
          <Text variant="title" color="white">
            Escolha a quantidade de jogadores.
          </Text>
          <Box
            fontSize="24px"
            textAlign="center"
            w="100%"
            display="flex"
            flexDirection="row"
          >
            <Box
              onClick={handlePeopleDown}
              cursor="pointer"
              flex={1 / 5}
              bg="green"
            >
              <Text>-</Text>
            </Box>
            <Box flex={3 / 5} bg="white">
              <Text>{numberPeople}</Text>
            </Box>
            <Box
              onClick={handlePeopleUp}
              cursor="pointer"
              flex={1 / 5}
              bg="green"
            >
              <Text>+</Text>
            </Box>
          </Box>
          <Text color="white">
            *Lembre-se: jogam, no máximo, 4 jogadores por partida!
          </Text>
        </Stack>

        <Stack>
          <Text mb={-4} variant="text" fontSize="lg">
            A partir de
          </Text>
          {isLoading && <Loading />}
          {!isLoading && (
            <>
              <HStack>
                <Text color="white" fontSize="36px">
                  {priceRoom}
                </Text>
                <Text variant="text">por pessoa</Text>
              </HStack>

              <Text variant="text">Total: {subTotal}</Text>
              <Text variant="text" fontSize={'xs'}>
                *Valor semanal
              </Text>
            </>
          )}
        </Stack>

        <Button onClick={() => navigate('/ingressos')} variant="pophausOutline">
          Reserve agora!
        </Button>
      </Stack>
    </DashedContainer>
  );
}

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useForm<SelectTicketFormValues>();
  const [smallerThan1390] = useMediaQuery('(max-width: 1390px)');
  const [smallerThan900] = useMediaQuery('(max-width: 900px)');
  const [largerThan500] = useMediaQuery('(min-width: 500px)');

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView();
  }, []);

  useEffect(() => {
    form.reset();
    dispatch(BookingActions.resetSearch());
  }, []);

  const openExternalForm = () => {
    window.open(
      'https://forms.monday.com/forms/71bcc81a6cc82569891840ff20b82948?r=use1',
      '_blank'
    );
  };

  return (
    <PageContainer containerProps={{ py: 0, px: 0, spacing: 0 }}>
      <Box overflow={'hidden'} position="relative" maxW="100vw">
        <Image
          opacity={0.4}
          position={'absolute'}
          left={0}
          top={0}
          width={'100%'}
          height={'auto'}
          src={BgHome}
          fit="cover"
          objectPosition={'center top'}
        />
        <Section
          // backgroundImage={`url(${BgHome})`}
          // backgroundPosition="center"
          // backgroundRepeat="no-repeat"
          // backgroundSize="cover"
          position="relative"
          px={{ base: 0, md: 64 }}
          minH={{ base: 'none', md: '75vh' }}
          direction={smallerThan900 ? 'column' : 'row'}
          gap={smallerThan900 ? 0 : 6}
        >
          <Text
            fontSize={smallerThan1390 ? '36px' : '56px'}
            fontWeight={'black'}
            lineHeight={'normal'}
            textTransform={'uppercase'}
            color="white"
            textAlign={{ base: 'center', md: 'unset' }}
            w="full"
          >
            Uma nova experiência em videogames
          </Text>

          <DashedContainer w="full">
            <SelectTicketForm form={form} direction="column" />
          </DashedContainer>
        </Section>
      </Box>
      <Section direction={smallerThan900 ? 'column' : 'row'} pt={12}>
        <VStack
          mb={smallerThan900 ? 6 : 0}
          align="flex-start"
          px={{ base: 0, md: 16 }}
          width="100%"
        >
          <Text
            fontSize={smallerThan1390 ? '36px' : '56px'}
            fontWeight={'black'}
            textTransform={'uppercase'}
            color="#fff"
          >
            O QUE É O<br /> ARCADE?
          </Text>
          <Text
            fontSize={'lg'}
            fontWeight={'medium'}
            color={'#fff'}
            pb={{ base: 8, md: 0 }}
          >
            O Arcade Haus é o primeiro fliperama totalmente imersivo da América
            Latina. Com tecnologia exclusiva desenvolvida nacionalmente,
            proporcionamos uma nova experiência em videogames que conecta
            gerações - dos nostálgicos pelos fliperamas aos ansiosos pelas novas
            tecnologias, todos se divertem nas salas do Arcade.
          </Text>
        </VStack>
        <Box
          display="flex"
          justifyContent="center"
          width={smallerThan900 ? '100%' : '80%'}
        >
          <VideoBox
            src={'https://youtu.be/e1-2l0mDOv4?feature=shared'}
            awaysFullWidth
            playOnFocus
          />
        </Box>
      </Section>
      <Section
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={{ base: '50px', md: '100px' }}
        px={{ base: '20px', md: '0px' }}
        py={{ base: '30px', md: '60px' }}
      >
        <VStack textAlign="center" align="center">
          <Text
            fontSize={{ base: '4xl', md: '6xl' }}
            fontWeight="black"
            textTransform="uppercase"
            color="#fff"
            px={{ base: '10px', md: '0px' }}
          >
            Monte sua unidade!
          </Text>
          <Text
            fontSize={{ base: 'md', md: 'lg' }}
            fontWeight="medium"
            color="#fff"
            pb={{ base: 8, md: 0 }}
            px={{ base: '10px', md: '0px' }}
          >
            Interessado em fazer parte da revolução na forma de jogar
            videogames?
          </Text>
          <Text
            fontSize={{ base: 'md', md: 'lg' }}
            fontWeight="medium"
            color="#fff"
            pb={{ base: 8, md: 0 }}
            textAlign="center"
            px={{ base: '10px', md: '0px' }}
          >
            Preencha o formulário abaixo e nossa equipe entrará em contato.
          </Text>
          <Box
            mt={{ base: '10px', md: '25px' }}
            display="flex"
            justifyContent="center"
            width="100%"
          >
            <Button
              variant="pophausOutline"
              onClick={openExternalForm}
              width={{ base: '80%', md: 'auto' }}
            >
              PREENCHER FORMULÁRIO!
            </Button>
          </Box>
        </VStack>
      </Section>

      {/* <Section pt={12} flexDirection="column">
        <Text
          fontSize={'56px'}
          fontWeight={'black'}
          textTransform={'uppercase'}
          color="#fff"
        >
          COMO FUNCIONA?
        </Text>
        <VStack
          align="flex-start"
          flexDirection="row"
          gap={10}
          px={{ base: 0, md: 16 }}
        >
          <Box
            bg="#B0CA0A"
            padding="25px 50px"
            width="40%"
            height="17.5rem"
            marginTop="0.5rem"
          >
            <Flex justifyContent="flex-end">
              <Image src={players} />
            </Flex>
            <Flex flexDirection="column">
              <Text
                fontSize={'lg'}
                fontWeight={'bold'}
                color={'black'}
                textTransform={'uppercase'}
                pb={{ base: 8, md: 0 }}
                marginBottom="10px"
              >
                Junte a Galera!
              </Text>
              <Text
                fontSize={'lg'}
                fontWeight={'medium'}
                color={'black'}
                pb={{ base: 8, md: 0 }}
              >
                Reúna um grupo de, no mínimo, <strong>4 pessoas.</strong> Quanto
                mais amigos trouxer, mais barato fica o valor por pessoa.
              </Text>
            </Flex>
          </Box>

          <Box bg="#00AEB3" padding="25px 50px" width="40%" height="17.5rem">
            <Flex justifyContent="flex-end">
              <Image src={rooms} />
            </Flex>
            <Flex flexDirection="column">
              <Text
                fontSize={'lg'}
                fontWeight={'bold'}
                color={'black'}
                textTransform={'uppercase'}
                pb={{ base: 8, md: 0 }}
                marginBottom="10px"
              >
                Faça a reserva
              </Text>
              <Text
                fontSize={'lg'}
                fontWeight={'medium'}
                color={'black'}
                pb={{ base: 8, md: 0 }}
                marginBottom="10px"
              >
                Reserve uma ou duas salas, a partir de{' '}
                <strong>R$39,90 por pessoa*.</strong>
              </Text>
              <Text
                fontSize={'small'}
                fontWeight={'normal'}
                color={'black'}
                pb={{ base: 8, md: 0 }}
                marginBottom="10px"
              >
                *Considerando a reserva de uma sala por 25 minutos, para 8
                pessoas
              </Text>
            </Flex>
          </Box>

          <Box bg="#F9E312" padding="25px 50px" width="40%" height="17.5rem">
            <Flex justifyContent="flex-end">
              <Image src={trophy} />
            </Flex>
            <Flex flexDirection="column">
              <Text
                fontSize={'lg'}
                fontWeight={'bold'}
                color={'black'}
                pb={{ base: 8, md: 0 }}
                marginBottom="10px"
              >
                DIVIRTA-SE
              </Text>
              <Text
                fontSize={'lg'}
                fontWeight={'medium'}
                color={'black'}
                pb={{ base: 8, md: 0 }}
              >
                Agora é só se divertir! No dia da visita escolha quais e quantos
                jogos quiser!
              </Text>
            </Flex>
          </Box>
        </VStack>
        <Box style={{ marginTop: '25px' }}>
          <Button
            bg="#00AEB3"
            variant="pophausOutline"
            onClick={() => navigate('/ingressos')}
          >
            RESERVE AGORA!
          </Button>
        </Box>
      </Section> */}

      <Section pt={12} flexDirection="column">
        <Text
          fontSize={smallerThan1390 ? '36px' : '56px'}
          fontWeight="black"
          textTransform="uppercase"
          color="#fff"
        >
          COMO FUNCIONA?
        </Text>
        <VStack
          align="flex-start"
          flexDirection={{ base: 'column', md: 'row' }}
          gap={10}
          px={{ base: 0, md: 16 }}
        >
          <Box
            width={{ base: '100%', md: '40%' }}
            border={'2px dashed'}
            borderColor={'green'}
            p={'5px'}
            h="100%"
          >
            <Box
              bg="#B0CA0A"
              padding={{ base: '10px 25px', md: '25px 50px' }}
              h="100%"
              justifyContent={'center'}
            >
              <Flex justifyContent="flex-end">
                <Image src={players} />
              </Flex>
              <Flex flexDirection="column" h="60%">
                <Text
                  fontSize={{ base: 'lg', md: 'lg' }}
                  fontWeight="bold"
                  color="black"
                  textTransform="uppercase"
                  pb={{ base: 8, md: 0 }}
                  marginBottom="10px"
                >
                  Junte a Galera!
                </Text>
                <Text
                  fontSize={{ base: 'lg', md: 'lg' }}
                  fontWeight="medium"
                  color="black"
                  pb={{ base: 8, md: 0 }}
                  my="auto"
                >
                  Chame 1 ou mais amigos para encarar o desafio com você.
                </Text>
              </Flex>
            </Box>
          </Box>

          <Box
            width={{ base: '100%', md: '40%' }}
            border={'2px dashed'}
            borderColor={'popPetGreen.bg'}
            p={'5px'}
            h="100%"
          >
            <Box
              bg="#00AEB3"
              padding={{ base: '10px 25px', md: '25px 50px' }}
              h="100%"
            >
              <Flex justifyContent="flex-end">
                <Image src={rooms} />
              </Flex>
              <Flex flexDirection="column">
                <Text
                  fontSize={{ base: 'lg', md: 'lg' }}
                  fontWeight="bold"
                  color="black"
                  textTransform="uppercase"
                  pb={{ base: 8, md: 0 }}
                  marginBottom="10px"
                >
                  INGRESSO AVULSO OU RESERVA DE SALA?
                </Text>
                <Text
                  fontSize={{ base: 'lg', md: 'lg' }}
                  fontWeight="medium"
                  color="black"
                  pb={{ base: 8, md: 0 }}
                  mb={'10px'}
                >
                  Depende do tamanho do grupo. Ingressos avulsos (de 2 a 6
                  pessoas) custam a partir de <strong>R$ 69</strong> e reservas
                  de sala (4 a 12 pessoas), a partir de <strong>R$ 49*</strong>{' '}
                  por pessoa
                </Text>
                <Text
                  fontSize={{ base: 'small', md: 'small' }}
                  fontWeight="normal"
                  color="black"
                  pb={{ base: 8, md: 0 }}
                >
                  *considerando a reserva sala para 12 pessoas
                </Text>
              </Flex>
            </Box>
          </Box>

          <Box
            width={{ base: '100%', md: '40%' }}
            border={'2px dashed'}
            borderColor={'yellow'}
            p={'5px'}
            h="100%"
          >
            <Box
              bg="#F9E312"
              padding={{ base: '10px 25px', md: '25px 50px' }}
              h="100%"
            >
              <Flex justifyContent="flex-end">
                <Image src={trophy} />
              </Flex>
              <Flex flexDirection="column" h="60%">
                <Text
                  fontSize={{ base: 'lg', md: 'lg' }}
                  fontWeight="bold"
                  color="black"
                  pb={{ base: 8, md: 0 }}
                  marginBottom="10px"
                >
                  DIVIRTA-SE
                </Text>
                <Text
                  fontSize={{ base: 'lg', md: 'lg' }}
                  fontWeight="medium"
                  color="black"
                  pb={{ base: 8, md: 0 }}
                  my="auto"
                >
                  Agora é só se divertir! No dia da visita escolha quais e
                  quantos jogos quiser!
                </Text>
              </Flex>
            </Box>
          </Box>
        </VStack>
        <Box style={{ marginTop: '25px' }}>
          <Button
            bg="#00AEB3"
            variant="pophausOutline"
            onClick={() => navigate('/ingressos')}
          >
            INGRESSOS E RESERVAS
          </Button>
        </Box>
      </Section>

      <Section
        display="flex"
        justifyContent="center"
        direction="column"
        gap="1rem"
      >
        <Stack textAlign="center">
          <Text
            fontSize={smallerThan1390 ? '36px' : '56px'}
            fontWeight="black"
            textTransform="uppercase"
            color="#fff"
            mb={-3}
          >
            Simule sua jornada
          </Text>
          <Text variant="text" fontSize={'lg'} fontWeight={'medium'}>
            Escolha entre ingresso avulso ou reserva de sala de acordo com a
            quantidade de jogadores.
          </Text>
        </Stack>

        <ContentPrices img={cacacoresImg} />
      </Section>

      <Section direction={smallerThan900 ? 'column' : 'row'} bg="green" pt={12}>
        <VStack
          w={smallerThan900 ? '100%' : smallerThan1390 ? '50%' : '100%'}
          mb={6}
          align="flex-start"
          px={{ base: 0, md: 16 }}
        >
          <Text
            fontSize={smallerThan1390 ? '36px' : '56px'}
            fontWeight={'black'}
            textTransform={'uppercase'}
            color="black"
          >
            Jogos
          </Text>
          <Text
            fontSize={'md'}
            fontWeight={'medium'}
            color={'black'}
            pb={{ base: 8, md: 4 }}
          >
            As partidas no Arcade são eletrizantes! Jogos que misturam
            agilidade, lógica e muita diversão! Com seu time, escolha entre 5
            níveis de dificuldade e alcance a maior pontuação possível. Será que
            vocês conseguem finalizar o nível Arcade Hero e ficar no topo do
            Ranking?
          </Text>
          <Button
            width="20%"
            bg="white"
            variant="pophausOutline"
            _hover={{ bg: 'white' }}
            onClick={() => navigate('/jogos')}
          >
            Conhecer os jogos!
          </Button>
        </VStack>
        <ImageBox
          marginTop={{ base: '15px', md: 0 }}
          title="video-home1"
          border="2px"
          borderColor={'white'}
          src={jogosImg}
        />
      </Section>

      {/* Eventos foi comentado temporariamente */}
      {/* <Section reverse pt={12}>
        <VStack align="flex-start" px={{ base: 0, md: 16 }}>
          <Text
            fontSize={'56px'}
            fontWeight={'black'}
            textTransform={'uppercase'}
            color="red"
          >
            Eventos
          </Text>
          <Text
            fontSize={'lg'}
            fontWeight={'medium'}
            color={'red'}
            pb={{ base: 8, md: 0 }}
          >
            Já imaginou comemorar com os amigos enquanto têm uma experiência
            totalmente inovadora em videogame? No Arcade Haus você e seus
            convidados entram no jogo -literalmente! E ainda aproveitam um
            delicioso buffet e um confortável espaço reservado. Solicite um
            orçamento personalizado.
          </Text>
          <Box style={{ marginTop: '25px' }}>
            <Button
              bg="red"
              variant="pophausOutline"
              onClick={() => navigate('/ingressos')}
            >
              RESERVE AGORA!
            </Button>
          </Box>
        </VStack>
        <ImageBox
          title="video-home2"
          border="2px"
          borderColor={'red'}
          src="https://www.youtube.com/embed/a0npbx1Ew-Y"
        />
      </Section> */}
    </PageContainer>
  );
}
