import { HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import Form from 'src/components/Form/Form';
import { FormInput } from 'src/components/Form/Input';

export interface EditBookingFormValues {
  name: string;
  document: string;
  email: string;
  date: string;
  time: string;
  ticket: number;
  room: string;
}

interface EditBookingFormProps {
  form: UseFormReturn<EditBookingFormValues, any>;
  readonly?: boolean;
}

export default function EditBookingForm({
  form,
  readonly = false,
}: EditBookingFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  const onSubmit = (data: EditBookingFormValues) => {
    return;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <FormInput
          label="NOME"
          rhfRegister={{
            ...register('name', {
              required: 'Campo obrigatório',
              disabled: readonly,
            }),
          }}
          errorState={errors.name}
          inputProps={{ placeholder: 'NOME', type: 'text' }}
        />
        <FormInput
          label="CPF"
          rhfRegister={{
            ...register('document', {
              required: 'Campo obrigatório',
              disabled: readonly,
            }),
          }}
          errorState={errors.document}
          inputProps={{ placeholder: '123.456.789-10', type: 'text' }}
        />
        <FormInput
          label="E-MAIL"
          rhfRegister={{
            ...register('email', {
              required: 'Campo obrigatório',
              disabled: readonly,
            }),
          }}
          errorState={errors.email}
          inputProps={{ placeholder: 'lucasovski@gmail.com', type: 'text' }}
        />
        <Stack direction={['column', 'row']}>
          <FormInput
            label="DATA"
            rhfRegister={{
              ...register('date', {
                required: 'Campo obrigatório',
                disabled: readonly,
              }),
            }}
            errorState={errors.date}
            inputProps={{ placeholder: 'DATA', type: 'date' }}
          />
          <FormInput
            label="HORÁRIO"
            rhfRegister={{
              ...register('time', {
                required: 'Campo obrigatório',
                disabled: readonly,
              }),
            }}
            errorState={errors.time}
            inputProps={{ placeholder: '13:00', type: 'text' }}
          />
          <FormInput
            label="INGRESSO"
            rhfRegister={{
              ...register('ticket', {
                required: 'Campo obrigatório',
                disabled: readonly,
              }),
            }}
            errorState={errors.ticket}
            inputProps={{ placeholder: '08', type: 'number' }}
          />
          <FormInput
            label="SALA"
            rhfRegister={{
              ...register('room', {
                required: 'Campo obrigatório',
                disabled: readonly,
              }),
            }}
            errorState={errors.room}
            inputProps={{ placeholder: '01', type: 'text' }}
          />
        </Stack>
      </Stack>
    </Form>
  );
}
