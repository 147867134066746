import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useFieldArray, useForm } from 'react-hook-form';
import PageContainer from 'src/components/Container/PageContainer';
import { DropdownTable } from 'src/components/Table/DropdownTable';
import { useAdminTicketsColumns } from 'src/pages/admin/tickets/TableData';
import DashedContainer from 'src/components/Container/DashedContainer';
import { AddPlayerModal } from 'src/components/Modal/ModalVariants/AddPlayerModal';
import PlayerDataForm, { PlayerDataValues } from 'src/modules/Forms/PlayerData';
import { FaEdit, FaRegWindowClose } from 'react-icons/fa';
import EditBookingForm, {
  EditBookingFormValues,
} from 'src/modules/Forms/Backoffice/EditBooking';
import {
  addBookingPlayers,
  buyMoreTickets,
  putIdentifyPlayers,
  useGetBookingByID,
} from 'src/api/services/booking';
import { useNavigate, useParams } from 'react-router-dom';
import { formatCurrency } from 'src/utils/format';
import dayjs from 'dayjs';
import { HiOutlinePlusCircle } from 'react-icons/hi2';
import usePHToast from 'src/hooks/useToast';
import {
  EditPlayer,
  PlayerRequest,
  PlayerResult,
} from 'src/api/interfaces/player';
import PlayerDataFieldArrayForm, {
  PlayerDataFieldArrayValues,
} from 'src/modules/Forms/PlayerData/PlayerDataFieldArray';
import { PophausModal } from 'src/components/Modal';
import { BsAlarm } from 'react-icons/bs';
import TicketsModal from 'src/modules/Modals/TicketsModal';

export default function AdminViewTicket() {
  const [bookingPlayers, setBookingPlayers] = useState<PlayerResult[]>([]);
  const { bookingID } = useParams<{ bookingID: string }>();
  const toast = usePHToast();
  const navigate = useNavigate();
  const reservCod = bookingID?.split('-')?.[0];
  const [fullDuration, setFulDuration] = useState(false)
  const [defaultTickets, setDefaultTickets] = useState(0)

  const {
    onClose: onRescheduleClose,
    onOpen: onRescheduleOpen,
    isOpen: isRescheduleOpen,
  } = useDisclosure();

  const {
    isOpen: isTicketsOpen,
    onOpen: onTicketsOpen,
    onClose: onTicketsClose
  } = useDisclosure()

  const { data: booking, mutate } = useGetBookingByID(bookingID);
  const room = booking?.bookingRooms?.[0];

  const rooms = booking?.bookingRooms
    .flatMap((br) => br.room?.description)
    .join(' e ');

  const bookingForm = useForm<EditBookingFormValues>({
    defaultValues: {
      name: booking?.user?.name,
      document: booking?.user?.document,
      email: booking?.user?.email,
      room: rooms,
      ticket: booking?.tickets,
      date: dayjs(booking?.startDate).format('YYYY-MM-DD'),
      time: dayjs(booking?.bookingRooms?.[0]?.startTime).format('HH:mm'),
    }
  });
  const playerForm = useForm<PlayerDataFieldArrayValues>({
    reValidateMode: 'onBlur',
  });
  const { control, reset, handleSubmit } = playerForm;
  const { fields, remove: removePlayer } =
    useFieldArray<PlayerDataFieldArrayValues>({
      control: control,
      name: 'playerFieldArray',
    });

  const [loading, setLoading] = useState(false);

  const [pendingPlayers, setPendingPlayers] = useState<boolean>(false);

  const setPlayerFields = () => {
    const players = booking?.bookingPlayers?.map((p) => ({
      ...p.player,
      birthDate: dayjs(p.player.birthDate).format('DD/MM/YYYY'),
    }));

    reset({ playerFieldArray: players });
  };

  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();

  const addPlayerForm = useForm<PlayerDataValues>({
    reValidateMode: 'onBlur',
    defaultValues: {
      nickName: '',
      name: '',
      lastName: '',
      birthDate: '',
    },
  });

  const addPlayer = async (data: PlayerDataValues) => {
    try {
      setLoading(true);
      if (!bookingID) throw new Error('ID da reserva inválido');
      const bookedPlayers = bookingPlayers?.flatMap((p) => p) ?? [];
      const players = [...bookedPlayers, data];

      const formattedPlayers: PlayerRequest[] = players.map((p) => {
        const brDate = p.birthDate?.includes('/');
        return {
          ...p,
          birthDate: brDate
            ? dayjs(p.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : p.birthDate,
        };
      });

      const res = await addBookingPlayers(bookingID, formattedPlayers);
      const p = res.bookingPlayers?.flatMap((p) => p.player) ?? bookedPlayers;
      reset({ playerFieldArray: p });
      setBookingPlayers(p);
      mutate();
    } catch (e) {
      toast({ status: 'error', description: String(e) });
    } finally {
      onAddClose();
      addPlayerForm.reset();
      setLoading(false);
    }
  };

  const handleSave = async (data: PlayerDataFieldArrayValues) => {
    try {
      setLoading(true);
      if (!bookingID) throw new Error('ID da reserva inválido');
      const formattedPlayers = data.playerFieldArray.map((p) => {
        const brDate = p.birthDate?.includes('/');
        return {
          ...p,
          birthDate: brDate
            ? dayjs(p.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : p.birthDate,
        };
      });
      const res = await addBookingPlayers(bookingID, formattedPlayers);

      toast({
        status: 'success',
        description: 'Jogadores atualizados com sucesso',
      });
      mutate();
    } catch (e) {
      toast({ status: 'error', description: String(e) });
    } finally {
      setLoading(false);
    }
  };

  const setIdentifyPlayersFalse = async () => {
    try {
      setLoading(true);
      if (!bookingID) throw new Error('ID da reserva é inválido!');
      const res = await putIdentifyPlayers(bookingID, false);
      mutate();
      toast({
        status: 'success',
        description: 'Jogadores não serão identificados',
      });
    } catch (e) {
      toast({ status: 'error', description: String(e) });
    } finally {
      setLoading(false);
    }
  };

  const setIdentifyPlayersTrue = async () => {
    try {
      setLoading(true);
      if (!bookingID) throw new Error('ID da reserva é inválido!');
      const res = await putIdentifyPlayers(bookingID, true);
      mutate();
      toast({
        status: 'success',
        description: 'Por favor, identifique os jogadores',
      });
    } catch (e) {
      toast({ status: 'error', description: String(e) });
    } finally {
      setLoading(false);
    }
  };

  const handleRescheduleBooking = () => {
    if (booking?.bookingTypeID != 1) {
      return navigate(`/eventos/reagendar/${bookingID}`)
    }
    return navigate(`/jogador/reagendar/${bookingID}`);
  };

  const handleCheckIn = async (data: PlayerDataFieldArrayValues) => {
    try {
      if (!bookingID) throw new Error('ID da reserva é inválido!');

      setLoading(true);
      //   const result = await addBookingPlayers(bookingID, players);
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  const handleBuyTickets = async (tickets: number) => {
    try {
      if (!bookingID) throw new Error('ID da reserva é inválido!');
      setLoading(true);

      const result = await buyMoreTickets(bookingID, tickets);
      if (result) {
        navigate(`/admin/adicionar-ingressos/resumo/${bookingID}`)
      }
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (booking && booking.bookingPlayers) {
      setPendingPlayers(
        booking.bookingPlayers.length >= booking.tickets ? false : true
      );

    }
    const p = booking?.bookingPlayers?.flatMap((p) => p.player);
    setBookingPlayers(p ?? []);
    setPlayerFields();
    if (booking) {
      setFulDuration(booking.duration > 1 ? true : false)
      setDefaultTickets(booking.tickets)
    }
  }, [booking]);

  return (
    <PageContainer
      restricted
      presentGoBack
      containerProps={{ justify: 'flex-start' }}
    >
      <VStack w='full' gap={3}>
        <DashedContainer mb={6} borderColor="yellow">
          <Text variant="title" color="yellow" alignSelf={'flex-start'} pb={4}>
            Reservado!
          </Text>

          <Stack direction={['column', 'row']} pt={4} spacing={8}>
            <Text variant="subtitle">
              Código da Reserva:
              <Text
                as="span"
                variant="subtitle"
                textTransform={'uppercase'}
                color="yellow"
              >
                {' '}
                {reservCod}
              </Text>
            </Text>
            <Text variant="subtitle">
              Nome:
              <Text
                as="span"
                variant="subtitle"
                textTransform={'uppercase'}
                color="yellow"
              >
                {` ${booking?.user?.name} ${booking?.user?.lastName}`}
              </Text>
            </Text>
            <Text variant="subtitle">
              Data e hora:
              <Text
                as="span"
                variant="subtitle"
                textTransform={'uppercase'}
                color="yellow"
              >
                {` ${dayjs(room?.date).format('DD/MM/YYYY')} DAS ${dayjs(
                  room?.startTime
                ).format('HH:mm')} ÀS ${dayjs(room?.endTime).format('HH:mm')}`}
              </Text>
            </Text>
            <Text variant="subtitle">
              Valor Total:
              <Text
                as="span"
                variant="subtitle"
                textTransform={'uppercase'}
                color="yellow"
              >
                {` ${formatCurrency(booking?.total ?? 0)}`}
              </Text>
            </Text>
            <Text variant="subtitle">
              Ingressos:
              <Text
                as="span"
                variant="subtitle"
                textTransform={'uppercase'}
                color="yellow"
              >
                {` ${booking?.tickets}`}
              </Text>
            </Text>
          </Stack>

          <Stack overflowX='scroll' w="full" py={8}>
            {booking?.identifyPlayers ? (
              <>
                {fields.map((f, i) => (
                  <Stack direction={'row'} key={f.id} align={'flex-end'}>
                    <PlayerDataFieldArrayForm
                      playerNum={i + 1}
                      index={i}
                      form={playerForm}
                      colorScheme="yellow"
                    />
                    <Box
                      color="white"
                      as="button"
                      _hover={{ color: 'yellow' }}
                      onClick={() => removePlayer(i)}
                    >
                      <Icon as={FaRegWindowClose} w={5} h={5} />
                      <Text>Excluir</Text>
                    </Box>
                  </Stack>
                ))}
              </>
            ) : (
              <Text variant="text">Jogadores não serão identificados</Text>
            )}
            {booking?.identifyPlayers && pendingPlayers && (
              <Button onClick={onAddOpen} gap={3} p={0}>
                <Icon as={HiOutlinePlusCircle} color="yellow" w={8} h={8} />
                <Text
                  variant="highlighted"
                  color="yellow"
                  textTransform={'uppercase'}
                >
                  Adicionar Jogador
                </Text>
              </Button>
            )}
          </Stack>

          <Stack
            direction={['column', 'row']}
            w="full"
            align="center"
            py={8}
            borderTop={'2px'}
            borderColor="yellow"
          >
            {booking?.identifyPlayers && (
              <Button
                variant="pophausOutline"
                colorScheme={'popTransparent'}
                w="full"
                onClick={handleSubmit(handleSave)}
                isLoading={loading}
                paddingInline={0}
              >
                Atualizar Dados
              </Button>
            )}

            {booking?.identifyPlayers ? (
              <Button
                w="full"
                variant="pophausOutline"
                colorScheme="popYellow"
                onClick={setIdentifyPlayersFalse}
                isLoading={loading}
                paddingInline={0}
              >
                não identificar jogadores
              </Button>
            ) : (
              <Button
                w="full"
                variant="pophausOutline"
                colorScheme="popGreen"
                onClick={setIdentifyPlayersTrue}
                isLoading={loading}
                paddingInline={0}
              >
                identificar jogadores
              </Button>
            )}
            <Button
              w="full"
              variant="pophausOutline"
              colorScheme="popYellow"
              onClick={onRescheduleOpen}
              isLoading={loading}
              paddingInline={0}
            >
              Reagendar
            </Button>
            {/* <Button
              w="full"
              variant="pophausOutline"
              colorScheme="popYellow"
              onClick={onTicketsOpen}
              isLoading={loading}
              paddingInline={0}
            >
              Comprar ingressos
            </Button> */}
          </Stack>
        </DashedContainer>
        <DashedContainer gap={6} textAlign='left' borderColor="yellow">
          <Text w='full' color='yellow' variant='title'>Usuário</Text>
          <EditBookingForm readonly form={bookingForm} />
          <Stack w='full'>
            <HStack w="100%" fontWeight="bold">
              <Text color="yellow">INGRESSOS:</Text>
              <Text color="white">
                {booking?.tickets ?? 0}
              </Text>
            </HStack>
            <HStack w="100%" fontWeight="bold">
              <Text color="yellow">VALOR TOTAL:</Text>
              <Text color="white">
                {formatCurrency(booking?.total ?? 0)}
              </Text>
            </HStack>

            {/* <Box>
              <Button
                variant="pophausOutline"
                colorScheme="popYellow"
                onClick={onTicketsOpen}
                isLoading={loading}
                paddingInline={3}
              >
                Comprar mais ingressos
              </Button>

            </Box> */}
          </Stack>
        </DashedContainer>
        <AddPlayerModal
          key={'addPlayerModal'}
          playerNum={(bookingPlayers?.length ?? 0) + 1}
          form={addPlayerForm}
          isOpen={isAddOpen}
          onClose={() => {
            onAddClose();
            playerForm.reset();
          }}
          handleMainClick={addPlayerForm.handleSubmit(addPlayer)}
          loading={loading}
        />
      </VStack>
      <PophausModal
        icon={BsAlarm}
        isOpen={isRescheduleOpen}
        onClose={onRescheduleClose}
        content="Deseja reagendar?"
        handleMainClick={handleRescheduleBooking}
        secondaryButtonText="voltar"
        handleSecondaryClick={onRescheduleClose}
      />
      <TicketsModal fullDuration={fullDuration} defaultTickets={defaultTickets} onConfirm={handleBuyTickets} isOpen={isTicketsOpen} onClose={onTicketsClose} />
    </PageContainer>
  );
}
