import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Box,
  TableProps,
  Text,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';

export type PaginatedTableProps<Data extends object> = {
  data?: Data[];
  columns: ColumnDef<Data, any>[];
  hasSort?: boolean;
  pageSize?: number;
};

export function PaginatedTable<Data extends object>({
  data,
  columns,
  hasSort = false,
  pageSize = 10,
  ...props
}: PaginatedTableProps<Data> & TableProps) {

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data: data ?? [],
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: pageSize,
      },
    },
  });

  return (
    <>
      <Box {...props}  >
        <Table  >
          {hasSort && (
            <Thead mb={5} mt={10} >
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div
                            style={{ cursor: 'pointer', color: 'white' }}
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' ↑',
                              desc: ' ↓',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
          )}
          <Tbody background="#1E1D20" border="2px dashed #B0CA0A">
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const meta: any = cell.column.columnDef.meta;
                  return (
                    <Td
                      borderBottom="2px dashed #B0CA0A"
                      color="white"
                      key={cell.id}
                      isNumeric={meta?.isNumeric}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>

      </Box>
      <Flex
        mt="10px"
        w="100%"
        color="green"
        justifyContent="center"
        alignItems="center"
      >
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <ArrowBackIcon w="24px" h="24px" />
        </button>
        <Text fontWeight="bold" fontSize="20px">
          {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
        </Text>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <ArrowForwardIcon w="24px" h="24px" />
        </button>
      </Flex>
    </>
  );
}
