import {
  Box,
  Button,
  Center,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetSchedulesBySingleConfigResult } from 'src/api/interfaces/booking';
import {
  createBooking,
  getSchedulesBySingleConfig,
  updateBooking,
} from 'src/api/services/booking';
import { FormAccordion } from 'src/components/Form/Accordion';
import { FormAccordionContent } from 'src/components/Form/Accordion/AccordionContent';
import { ScheduleButton } from 'src/components/Form/ScheduleButton';
import { Loading } from 'src/components/Loading';
import usePHToast from 'src/hooks/useToast';
import { BookingActions } from 'src/redux/booking';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import TiktokPixel from 'tiktok-pixel';

interface SingleScheduleButtonsProps {
  date: string;
  tickets: number;
  businessUnitID: number;
  price: number;
  reschedulingBookingID?: string;
}

const SingleScheduleButtons = ({
  date,
  tickets,
  businessUnitID,
  price,
  reschedulingBookingID,
}: SingleScheduleButtonsProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = usePHToast();
  const { user } = useAppSelector((state) => state.user);
  const [expandedSchedulesAccordion, setExpandedSchedulesAccordion] =
    useState<number>(0);
  const [loadingSchedules, setLoadingSchedules] = useState<boolean>(false);
  const [schedules, setSchedules] = useState<
    GetSchedulesBySingleConfigResult[]
  >([]);
  const [selectedSchedule, setSelectedSchedule] =
    useState<GetSchedulesBySingleConfigResult>();
  const [loadingBookingCreation, setLoadingBookingCreation] =
    useState<boolean>(false);

  const handleSelectSchedule = (index: number): void => {
    setSelectedSchedule(schedules[index]);
  };

  const getData = () => {
    setLoadingSchedules(true);
    getSchedulesBySingleConfig({ date, tickets, businessUnitID })
      .then((res) => {
        setSchedules(res);
      })
      .catch((err) => {
        console.log({ err });

        toast({ status: 'error', title: 'Erro ao buscar horários!' });
      })
      .finally(() => setLoadingSchedules(false));
  };

  useEffect(() => {
    if (expandedSchedulesAccordion === 0) {
      if (date && tickets > 0 && businessUnitID > 0) getData();
    } else {
      setSchedules([]);
    }
  }, [date, tickets, businessUnitID, expandedSchedulesAccordion]);

  const handleContinue = () => {
    if (!selectedSchedule)
      return toast({
        status: 'warning',
        title: 'Por favor, selecione um horário válido.',
      });

    setLoadingBookingCreation(true);

    const userID = user?.userID ?? null;
    const startTime = selectedSchedule.time;
    const endTime = dayjs(selectedSchedule.time, 'HH:mm')
      .add(30, 'minutes')
      .format('HH:mm');

    const bookingRooms = [
      {
        roomID:
          selectedSchedule.roomsIDs[0] !== 0
            ? selectedSchedule.roomsIDs[0]
            : selectedSchedule.roomsIDs[1],
        startTime,
        endTime,
      },
    ];

    if (!businessUnitID) throw new Error('Unidade indefinida!');

    const bookingRequest = {
      date,
      userID,
      tickets,
      bookingRooms,
      bookingTypeID: 5,
      businessUnitID,
    };

    if (reschedulingBookingID)
      return updateBooking(reschedulingBookingID, bookingRequest)
        .then(() => {
          toast({
            status: 'success',
            title: 'Reserva reagendada com sucesso!',
          });
          navigate(`/jogador/ingressos-ativos`);
        })
        .catch((err) => {
          console.log({ err });

          toast({
            status: 'error',
            title: 'Não foi possível reagendar a reserva!',
          });
        })
        .finally(() => {
          setLoadingBookingCreation(false);
        });

    window.gtag('event', 'add_to_cart');
    TiktokPixel.track('AddToCart', {});

    createBooking(bookingRequest)
      .then((result) => {
        if (!userID) {
          dispatch(BookingActions.setBookingId(result.bookingID));
          dispatch(BookingActions.setBookingTypeID(result.bookingTypeID));
          return navigate('/login');
        }

        navigate(`/ingressos/jogadores/${result.bookingID}`);
      })
      .catch((err) => {
        console.log({ err });

        toast({
          status: 'error',
          title: 'Não foi possível reservar o horário selecionado!',
        });
      })
      .finally(() => setLoadingBookingCreation(false));
  };

  return (
    <>
      <Text variant={'title'}>Selecione um horário</Text>
      <Text variant={'text'} color={'white'}>
        Escolha o melhor horário pra vocês! As vagas são limitadas, então não
        perca tempo e garanta já o seu lugar!
      </Text>
      <FormAccordion
        onChange={setExpandedSchedulesAccordion}
        index={expandedSchedulesAccordion}
      >
        <FormAccordionContent title="Horários disponíveis">
          <VStack py={4} px={2}>
            {loadingSchedules && (
              <Center w="full">
                <Loading />
              </Center>
            )}
            {!loadingSchedules && (
              <>
                <Wrap
                  spacingX={6}
                  spacingY={4}
                  align="center"
                  justify={{ base: 'center', md: 'unset' }}
                >
                  {schedules.map((schedule, ix) => (
                    <WrapItem key={`schedule_item_${schedule.time}`}>
                      <ScheduleButton
                        index={ix}
                        isSelected={schedule.time === selectedSchedule?.time}
                        handleSelect={handleSelectSchedule}
                        time={schedule.placeHolder}
                        available
                      />
                    </WrapItem>
                  ))}
                </Wrap>
              </>
            )}
            <Text variant={'text'} color={'black'} me={'auto'} mt={12}>
              Capacidade: <strong>8 pessoas</strong> por horário
            </Text>
            <Text variant={'text'} fontSize={'xs'} color={'gray'} me={'auto'}>
              Capacidade de participantes na sala: <strong>4 jogadores</strong>
            </Text>
          </VStack>
        </FormAccordionContent>
      </FormAccordion>
      {selectedSchedule && (
        <Box
          bg={'white'}
          w={'full'}
          p={'1rem'}
          display={'flex'}
          mt={'-8px'}
          flexDirection={['column', 'row']}
        >
          <Box>
            <Text textTransform={'uppercase'}>
              Data: <strong>{dayjs(date).format('DD/MM/YYYY')}</strong>
            </Text>
            <Text textTransform={'uppercase'}>
              Participantes: <strong>{tickets} pessoas</strong>
            </Text>
            <Text textTransform={'uppercase'}>
              Duração: <strong>55 minutos</strong>
            </Text>
            <Text textTransform={'uppercase'}>
              Preço por participante:{' '}
              <strong>
                {price.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </strong>
            </Text>
            <Text textTransform={'uppercase'}>
              Subtotal:{' '}
              <strong>
                {(price * tickets).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </strong>
            </Text>
          </Box>
          <Button
            variant={'pophausOutline'}
            ms={'auto'}
            my={'auto'}
            onClick={handleContinue}
            isLoading={loadingBookingCreation}
          >
            Continuar
          </Button>
        </Box>
      )}
    </>
  );
};

export default SingleScheduleButtons;
