import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import TikTokPixel from 'tiktok-pixel';
import PageContainer from 'src/components/Container/PageContainer';
import {
  Box,
  Button,
  Center,
  HStack,
  SimpleGrid,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import DashedContainer from 'src/components/Container/DashedContainer';
import SelectTicketForm, {
  SelectTicketFormValues,
} from 'src/modules/Forms/SelectTicketForm';
import { FormAccordion } from 'src/components/Form/Accordion';
import { useForm } from 'react-hook-form';
import { FormAccordionContent } from 'src/components/Form/Accordion/AccordionContent';
import usePHToast from 'src/hooks/useToast';
import { getBusinessUnit } from 'src/api/services/businessUnit';
import { BusinessUnitResult } from 'src/api/interfaces/businessUnit';
import { useAppSelector } from 'src/redux/hooks';
import { Loading } from 'src/components/Loading';
import { ScheduleButtons } from 'src/components/Form/ScheduleButtons';
import BookingNotification from 'src/components/Notification';
import { useGetUserBookingNotifications } from 'src/api/services/notification/intex';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getBookingTypesByConfig } from 'src/api/services/booking';
import {
  GetBookingTypesByConfigRequest,
  GetBookingTypesByConfigResult,
} from 'src/api/interfaces/booking';
import BookingTypeItem from './BookingTypeItem';
import SingleScheduleButtons from 'src/components/SingleScheduleButtons';

const handleMultipleRooms = (
  businessUnit: BusinessUnitResult,
  numberOfPlayers: number
) => {
  if (numberOfPlayers < 8 || businessUnit.rooms.length < 2) return businessUnit;
  const roomIDs = businessUnit.rooms.flatMap((r) => r.roomID);
  const description = `${businessUnit.rooms
    .map((r) => r.description)
    .join(' e ')} (horários combinados)`;
  const combinedRoom = {
    ...businessUnit.rooms[0],
    roomID: roomIDs,
    description,
  };

  const newBusinessUnit = {
    ...businessUnit,
    rooms: [combinedRoom, ...businessUnit.rooms],
  };
  return newBusinessUnit;
};

export default function Ticket() {
  const toast = usePHToast();
  const { search } = useAppSelector((state) => state.booking);
  const { user } = useSelector((state: RootState) => state.user);
  const [smallerThan1000] = useMediaQuery('(max-width: 1000px)');
  const [errorMessage, setErrorMessage] = useState<string>();

  const form = useForm<SelectTicketFormValues>({
    values: search,
  });

  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedBusinessUnitID, setSelectedBusinessUnitID] =
    useState<number>(0);
  const [selectedPlayerNumber, setSelectedPlayerNumber] = useState(0);
  const [businessUnit, setBusinessUnit] = useState<BusinessUnitResult | null>(
    null
  );
  const [loadingBookingTypes, setLoadingBookingTypes] =
    useState<boolean>(false);
  const [bookingTypes, setBookingTypes] = useState<
    GetBookingTypesByConfigResult[]
  >([]);
  const [selectedBookingType, setSelectedBookingType] =
    useState<GetBookingTypesByConfigResult>();

  const {
    data: bookingNotifications,
    isLoading,
    mutate,
  } = useGetUserBookingNotifications(user ? user?.userID : '');

  const [expandedRoomAccordion, setExpandedRoomAccordion] =
    useState<number>(-1);
  const [expandedBookingTypeAccordion, setExpandedBookingTypeAccordion] =
    useState<number>(-1);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    TikTokPixel.pageView();
  }, []);

  const handleSearch = async (businessUnitID: number) => {
    try {
      setLoading(true);
      const result = await getBusinessUnit(businessUnitID);
      setBusinessUnit(result);
      window.gtag('event', 'view_item');
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
      setErrorMessage(undefined);
    }
  };

  const handleBookingTypesSearch = async (data: SelectTicketFormValues) => {
    try {
      setLoadingBookingTypes(true);
      const result = await getBookingTypesByConfig({
        Tickets: +data.numberOfPlayers,
        BusinessUnitID: +data.businessUnitID,
        Date: data.date,
      });
      setBookingTypes(result);
      setSelectedDate(data.date);
      setSelectedPlayerNumber(+data.numberOfPlayers);
      setSelectedBusinessUnitID(+data.businessUnitID);
      setSelectedBookingType(result[1] ? undefined : result[0]);
      setExpandedBookingTypeAccordion(result[1] ? 0 : -1);
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoadingBookingTypes(false);
      setErrorMessage(undefined);
    }
  };

  const handleBookingTypeClick = (
    bookingType: GetBookingTypesByConfigResult
  ) => {
    setExpandedBookingTypeAccordion(-1);
    setSelectedBookingType(bookingType);
  };

  useEffect(() => {
    if (selectedBusinessUnitID > 0 && selectedBookingType)
      if (selectedBookingType.id === 1) handleSearch(selectedBusinessUnitID);
  }, [selectedBookingType, selectedBusinessUnitID]);

  const showRoomTypeButtons =
    !isLoading &&
    !loadingBookingTypes &&
    businessUnit?.rooms &&
    selectedBookingType?.id === 1 &&
    expandedBookingTypeAccordion === -1;

  const showSingleTypeButtons =
    !isLoading &&
    !loadingBookingTypes &&
    selectedBookingType?.id === 5 &&
    selectedBusinessUnitID &&
    selectedPlayerNumber &&
    selectedDate &&
    expandedBookingTypeAccordion === -1;

  useEffect(() => {
    const formValues = form.getValues();
    if (
      formValues.date &&
      formValues.businessUnitID &&
      formValues.numberOfPlayers
    ) {
      handleBookingTypesSearch(form.getValues());
    }
  }, []);

  return (
    <PageContainer>
      <VStack w="full" spacing={2} py={{ base: 8, md: 0 }}>
        <Text variant="title">SELECIONE OS INGRESSOS</Text>
        <DashedContainer justify="center">
          <VStack w="full" spacing={2} align="flex-start">
            <SelectTicketForm
              form={form}
              overrideSubmit={handleBookingTypesSearch}
              direction={smallerThan1000 ? 'column' : 'row'}
              externalLoading={loading}
            />
            {loadingBookingTypes && (
              <Center w="full">
                <Loading />
              </Center>
            )}
            {!loadingBookingTypes && bookingTypes[0] && (
              <FormAccordion
                onChange={
                  bookingTypes[1] ? setExpandedBookingTypeAccordion : undefined
                }
                index={expandedBookingTypeAccordion}
              >
                <FormAccordionContent
                  title={
                    (expandedBookingTypeAccordion === -1 &&
                      selectedBookingType?.name) ||
                    'Escolha o tipo de reserva'
                  }
                  bg={'black'}
                >
                  {bookingTypes[1] && (
                    <SimpleGrid minChildWidth={'300px'} w="full" gap={'1rem'}>
                      {bookingTypes.map((bookingType) => (
                        <BookingTypeItem
                          key={`booking_type_item_${bookingType.id}`}
                          bookingType={bookingType}
                          onClick={handleBookingTypeClick}
                          selectedBookingTypeID={selectedBookingType?.id}
                        />
                      ))}
                    </SimpleGrid>
                  )}
                </FormAccordionContent>
              </FormAccordion>
            )}
            {(isLoading || loadingBookingTypes) && (
              <Center w="full">
                <Loading />
              </Center>
            )}
            {showRoomTypeButtons && (
              <>
                <Text color="yellow" variant="subtitle">
                  Atenção: as salas vermelha e azul são iguais, escolha a que
                  tiver o melhor horário disponível para você. Se quiser mais
                  tempo de diversão, selecione mais de um horário em sequência.
                </Text>
                <Text variant="title">SELECIONE O HORÁRIO</Text>
                {!errorMessage ? (
                  <FormAccordion
                    onChange={setExpandedRoomAccordion}
                    index={expandedRoomAccordion}
                  >
                    {businessUnit.rooms.map((r, ix) => (
                      <FormAccordionContent
                        key={
                          Array.isArray(r.roomID)
                            ? `combined-${r.roomID[0]}`
                            : r.roomID
                        }
                        title={r.description}
                      >
                        <ScheduleButtons
                          activeRoom={ix === expandedRoomAccordion}
                          roomIDs={r.roomID}
                          date={selectedDate}
                          numberOfPlayers={selectedPlayerNumber}
                          price={r.price}
                          businessUnitID={r.businessUnitID}
                          setErrorMsg={setErrorMessage}
                        />
                      </FormAccordionContent>
                    ))}
                  </FormAccordion>
                ) : (
                  <Text variant="text" color="red">
                    {errorMessage}
                  </Text>
                )}
              </>
            )}
            {showSingleTypeButtons && (
              <SingleScheduleButtons
                businessUnitID={selectedBusinessUnitID}
                tickets={selectedPlayerNumber}
                date={selectedDate}
                price={selectedBookingType.price}
              />
            )}
          </VStack>
        </DashedContainer>
      </VStack>
      <HStack>
        {bookingNotifications?.map((i) => (
          <>
            {i.active && (
              <BookingNotification
                key={i.alertID}
                notificationID={i.alertID}
                bookingID={i.bookingID}
                message={i.message}
                mutate={mutate}
              />
            )}
          </>
        ))}
      </HStack>
    </PageContainer>
  );
}
