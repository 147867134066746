import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { PlayerResult } from 'src/api/interfaces/player';
import DashedContainer from 'src/components/Container/DashedContainer';

interface ExistingPlayerConfirmationModalProps {
  open: boolean;
  handleConfirm: (isConfirmed: boolean) => void;
  existingPlayer: PlayerResult | undefined;
}

const ExistingPlayerConfirmationModal = ({
  open,
  handleConfirm,
  existingPlayer,
}: ExistingPlayerConfirmationModalProps) => {
  const handleClose = () => {
    handleConfirm(false);
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        onClose={handleClose}
        isOpen={open}
        isCentered
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent>
          <DashedContainer borderColor={'green'}>
            <ModalCloseButton
              size="sm"
              position="unset"
              alignSelf={'flex-end'}
              color={'green'}
              border={'2px'}
              borderRadius={0}
              borderColor={'green'}
            />
            <ModalBody w="full">
              <VStack spacing={4} w="full" align={'center'}>
                {existingPlayer && (
                  <Text variant="title" color={'white'} textAlign={'center'}>
                    Já existe o jogador com nickname{' '}
                    <Text as={'span'} color={'orange'}>
                      {existingPlayer.nickName}
                    </Text>
                    , CPF{' '}
                    <Text as={'span'} color={'orange'}>
                      {existingPlayer.document}
                    </Text>
                    , deseja confirmar a inclusão do jogador? Ou clique em
                    cancelar e escolha outro nickname!
                  </Text>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <VStack spacing={4} w="full">
                <Button
                  w="full"
                  variant="pophausOutline"
                  colorScheme={'popGreen'}
                  onClick={() => handleConfirm(true)}
                >
                  {'Confirmar'}
                </Button>
              </VStack>
            </ModalFooter>
          </DashedContainer>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExistingPlayerConfirmationModal;
