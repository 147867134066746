import {
  FaUserPlus,
  FaDollarSign,
  FaTicketAlt,
  FaRegCalendar,
  FaMoneyBill,
  FaPercent,
  FaCalendar,
  FaBars,
  FaCalendarPlus,
} from 'react-icons/fa';
import { NavItemChildProps } from '../NavItem';
import { BsFillChatLeftQuoteFill } from 'react-icons/bs';
import { MdPartyMode } from 'react-icons/md';

export const adminMenuItems: Array<NavItemChildProps> = [
  { name: 'Agenda', icon: FaRegCalendar, url: '/admin/agenda' },
  // { name: 'Compra', icon: FaMoneyBill, url: '/admin/agenda' },
  { name: 'Ingressos', icon: FaTicketAlt, url: '/admin/ingressos' },
  { name: 'Cadastros', icon: FaUserPlus, url: '/admin/cadastros' },
  { name: 'Financeiro', icon: FaDollarSign, url: '/admin/financeiro' },
  { name: 'Cupons', icon: FaPercent, url: '/admin/cupons' },
  { name: 'Calendário', icon: FaCalendar, url: '/admin/calendario' },
  { name: 'Lista de eventos', icon: FaBars, url: '/admin/eventos' },
  {
    name: 'Agendamento de eventos',
    icon: FaCalendarPlus,
    url: '/admin/eventos/novo',
  },
  { name: 'FAQ', icon: BsFillChatLeftQuoteFill, url: '/admin/FAQ' },
];
