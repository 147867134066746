import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import PageContainer from 'src/components/Container/PageContainer';
import { DropdownTable } from 'src/components/Table/DropdownTable';
import { useAdminTicketsColumns } from 'src/pages/admin/tickets/TableData';
import DashedContainer from 'src/components/Container/DashedContainer';
import { AddPlayerModal } from 'src/components/Modal/ModalVariants/AddPlayerModal';
import { PlayerDataValues } from 'src/modules/Forms/PlayerData';
import { FaEdit, FaRegWindowClose } from 'react-icons/fa';
import EditBookingForm, {
  EditBookingFormValues,
} from 'src/modules/Forms/Backoffice/EditBooking';
import {
  addBookingPlayers,
  putIdentifyPlayers,
  useGetBookingByID,
} from 'src/api/services/booking';
import { useParams } from 'react-router-dom';
import { formatCurrency } from 'src/utils/format';
import dayjs from 'dayjs';
import { HiOutlinePlusCircle, HiOutlineXCircle } from 'react-icons/hi2';
import usePHToast from 'src/hooks/useToast';
import { EditPlayer } from 'src/api/interfaces/player';
import { Loading } from 'src/components/Loading';
import BotsPlaceholder from 'src/components/BotsPlaceholder';

export default function AdminEditTicket() {
  const { bookingID } = useParams<{ bookingID: string }>();
  const toast = usePHToast();
  const columns = useAdminTicketsColumns();
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const bookingForm = useForm<EditBookingFormValues>();
  const playerForm = useForm<PlayerDataValues>({
    reValidateMode: 'onBlur',
    defaultValues: {
      nickName: '',
      name: '',
      lastName: '',
      birthDate: '',
      document: ''
    },
  });

  const [loading, setLoading] = useState(false);
  const [playerNumber, setPlayerNumber] = useState(0);
  const [players, setPlayers] = useState<EditPlayer[]>([]);

  const {
    data: booking,
    isLoading: loadingBooking,
    mutate,
  } = useGetBookingByID(bookingID);

  const handleSave = async (data: any) => {
    try {
      if (!bookingID) throw new Error('ID da reserva é inválido!');

      setLoading(true);
      const formattedPlayers = players.map((p) => {
        const brDate = p.birthDate?.includes('/');
        return {
          ...p,
          birthDate: brDate
            ? dayjs(p.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : p.birthDate,
        };
      });
      const result = await addBookingPlayers(bookingID, formattedPlayers);

      if (result?.bookingPlayers) {
        const dbPlayers = result.bookingPlayers.map((bp) => bp.player);
        setPlayers(dbPlayers);
      }

      toast({ status: 'success', title: 'Salvo com sucesso' });

    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  const addPlayer = (data: PlayerDataValues) => {
    setPlayers((prevState) => [...prevState, data]);
    onAddClose();
    playerForm.reset();
  };

  const editPlayer = (data: PlayerDataValues) => {
    const playerNumberAsIndex = playerNumber - 1;
    const edittedPlayers = players.map((p, i) => {
      if (i === playerNumberAsIndex) return data;
      return p;
    });
    setPlayers(edittedPlayers);
    onEditClose();
    playerForm.reset();
  };

  const deletePlayer = (playerIndex: number) => {
    setPlayers(players.filter((_, i) => i !== playerIndex));
  };

  const identifyPlayers = async () => {
    try {
      setLoading(true);
      if (booking) {
        const res = await putIdentifyPlayers(booking?.bookingID);
        toast({
          status: 'success',
          description: 'Jogadores não serão identificados',
        });
        mutate();
      }
    } catch (e) {
      toast({ status: 'error', description: String(e) });
    } finally {
      setLoading(false);
    }
  };

  const setIdentifyPlayersTrue = async () => {
    try {
      setLoading(true);
      if (booking) {
        const res = await putIdentifyPlayers(booking.bookingID, true);
        toast({
          status: 'success',
          description: 'Por favor, identifique os jogadores',
        });
        mutate();
      }
    } catch (e) {
      toast({ status: 'error', description: String(e) });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const user = booking?.user;
    const rooms = booking?.bookingRooms
      .flatMap((br) => br.room?.description)
      .join(' e ');
    const players = booking?.bookingPlayers?.map((p) => p.player);

    setPlayers(players ?? []);
    bookingForm.reset({
      name: user?.name,
      document: user?.document,
      email: user?.email,
      date: dayjs(booking?.startDate).format('YYYY-MM-DD'),
      time: dayjs(booking?.bookingRooms?.[0]?.startTime).format('HH:mm'),
      ticket: booking?.tickets,
      room: rooms ?? '',
    });
  }, [booking]);

  return (
    <PageContainer
      restricted
      presentGoBack
      containerProps={{ justify: 'flex-start' }}
    >
      {loadingBooking && <Loading />}
      {!loadingBooking && booking && (
        <>
          <DropdownTable data={[booking]} columns={columns} padding={0} />
          <Stack direction={['column', 'row']} w="full">
            <DashedContainer>
              <Stack
                direction="column"
                w="full"
                align="flex-start"
                justify={'flex-start'}
              >
                <Text variant="title">Jogadores</Text>

                {booking.identifyPlayers ? (
                  <>
                    <Stack w="full">
                      {players?.map((p, i) => (
                        <HStack key={p?.nickName}>
                          <Input
                            bg="white"
                            borderRadius={0}
                            value={p?.nickName}
                            readOnly
                          />
                          <Box
                            color="white"
                            as="button"
                            _hover={{ color: '#B0CA0A' }}
                            onClick={() => {
                              playerForm.setValue('nickName', p.nickName ?? '');
                              playerForm.setValue('name', p.name ?? '');
                              playerForm.setValue('lastName', p.lastName ?? '');
                              playerForm.setValue('document', p.document ?? '');
                              playerForm.setValue(
                                'birthDate',
                                dayjs(p?.birthDate)
                                  .format('DD/MM/YYYY')
                                  .toString()
                              );
                              setPlayerNumber(i + 1);
                              onEditOpen();
                            }}
                          >
                            <Icon as={FaEdit} w={5} h={5} />
                            <Text>Editar</Text>
                          </Box>
                          <Box
                            color="white"
                            as="button"
                            _hover={{ color: '#B0CA0A' }}
                            onClick={() => deletePlayer(i)}
                          >
                            <Icon as={FaRegWindowClose} w={5} h={5} />
                            <Text>Excluir</Text>
                          </Box>
                        </HStack>
                      ))}
                    </Stack>
                    <Stack w="full" align="center">
                      <Button onClick={onAddOpen} gap={3} p={0}>
                        <Icon
                          as={HiOutlinePlusCircle}
                          color="green"
                          w={8}
                          h={8}
                        />
                        <Text variant="highlighted" textTransform={'uppercase'}>
                          Adicionar Jogador
                        </Text>
                      </Button>

                      <Button onClick={identifyPlayers} gap={3} p={0}>
                        <Icon as={HiOutlineXCircle} color="green" w={8} h={8} />
                        <Text variant="highlighted" textTransform={'uppercase'}>
                          Não identificar jogadores
                        </Text>
                      </Button>
                    </Stack>
                  </>
                ) : (
                  <>
                    <VStack>
                      {Array.from({ length: booking.tickets }, (_, index) => (
                        <BotsPlaceholder key={index} number={index} />
                      ))}
                    </VStack>
                    <Button onClick={setIdentifyPlayersTrue} gap={3} p={0}>
                      <Icon
                        as={HiOutlinePlusCircle}
                        color="green"
                        w={8}
                        h={8}
                      />
                      <Text variant="highlighted" textTransform={'uppercase'}>
                        Identificar jogadores
                      </Text>
                    </Button>
                  </>
                )}
              </Stack>
            </DashedContainer>

            <DashedContainer>
              <Stack direction="column" w="full" align="center">
                <EditBookingForm form={bookingForm} readonly />
                <HStack w="100%" fontWeight="bold">
                  <Text color="green">VALOR TOTAL:</Text>
                  <Text color="white">
                    {formatCurrency(booking?.total ?? 0)}
                  </Text>
                </HStack>
                <Button
                  variant="pophausOutline"
                  colorScheme={'popTransparent'}
                  w="full"
                  onClick={handleSave}
                  isLoading={loading}
                >
                  Salvar alterações
                </Button>
                {/* <Button w="full" variant="pophausOutline">
                  Comprar mais ingressos
                </Button> */}
              </Stack>
            </DashedContainer>
          </Stack>
        </>
      )}

      <AddPlayerModal
        key={'addPlayerModal'}
        playerNum={(players?.length ?? 0) + 1}
        form={playerForm}
        isOpen={isAddOpen}
        onClose={() => {
          onAddClose();
          playerForm.reset();
        }}
        handleMainClick={playerForm.handleSubmit(addPlayer)}
      />
      <AddPlayerModal
        key={'editPlayerModal'}
        isEdit
        playerNum={playerNumber}
        form={playerForm}
        isOpen={isEditOpen}
        onClose={() => {
          onEditClose();
          playerForm.reset();
        }}
        handleMainClick={playerForm.handleSubmit(editPlayer)}
      />
    </PageContainer>
  );
}

<h1>Boa tarde!</h1>;

React.createElement('h1', null, 'Boa tarde!');
