import React, { useState } from 'react';
import { CalendarIcon, TimeIcon } from '@chakra-ui/icons';
import {
  Box,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { FaEdit, FaRegWindowClose, FaStar, FaTicketAlt } from 'react-icons/fa';
import { FiUser } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { BookingResult } from 'src/api/interfaces/booking';
import dayjs from 'dayjs';
import { ErrorModal } from 'src/components/Modal/ModalVariants/ErrorModal';
import { cancelBooking } from 'src/api/services/booking';
import usePHToast from 'src/hooks/useToast';
import { BsAlarm } from 'react-icons/bs';
import { PophausModal } from 'src/components/Modal';
import { cancelEvent } from 'src/api/services/event';

export const useAdminEventColumns = (realoadGet?: any) => {
  const columnHelper = createColumnHelper<BookingResult>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const columns: any = [
    columnHelper.accessor('startDate', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <CalendarIcon />

          <Text variant="text">
            {dayjs(info.getValue()).format('DD/MM/YYYY')}
          </Text>
        </Stack>
      ),
      header: () => <span>Data de uso</span>,
    }),

    columnHelper.accessor((r) => r.user?.name, {
      id: 'user.name',
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <FiUser />
          <Text variant="text">{`${info.getValue()} ${info.row.original.user?.lastName}`}</Text>
        </Stack>
      ),
      header: () => <span>Nome</span>,
    }),

    columnHelper.accessor('bookingRooms', {
      cell: (info) => {
        const rooms = info.getValue();
        const room = rooms?.[0];
        return (
          <Stack direction="row" alignItems="center">
            <TimeIcon />

            <Text variant="text">{`${dayjs(room?.startTime).format(
              'HH:mm'
            )} às ${dayjs(room?.endTime).format('HH:mm')}`}</Text>
          </Stack>
        );
      },
      header: () => <span>Horário</span>,
    }),

    columnHelper.accessor('bookingType.description', {
      cell: (info) => {
        return (
          <Stack direction="row" alignItems="center">
            <FaStar />

            <Text>{info.getValue()}</Text>
          </Stack>
        );
      },
      header: () => <span>Tipo do Evento</span>,
    }),

    columnHelper.accessor('tickets', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <FaTicketAlt />
          <Text variant="text">{`${info.getValue()} ingressos`}</Text>
        </Stack>
      ),
      header: () => <span>Ingressos</span>,
    }),

    columnHelper.accessor('created', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">
          <CalendarIcon />

          <Text variant="text">
            {dayjs(info.getValue()).format('DD/MM/YYYY')}
          </Text>
        </Stack>
      ),
      header: () => <span>Data da Venda</span>,
    }),

    columnHelper.accessor('bookingStatus', {
      cell: (info) => (
        <Stack direction="row" alignItems="center">

          <Text variant="text">{`${info.getValue()?.description}`}</Text>
        </Stack>
      ),
      header: () => <span>status da reserva</span>,
      sortingFn: (rowA, rowB, columnId) => {
        const statusA: any = rowA.getValue(columnId)
        const statusB: any = rowB.getValue(columnId)

        return statusA.bookingStatusID > statusB.bookingStatusID ? 1 : statusA.bookingStatusID < statusB.bookingStatusID ? -1 : 0
      }
    }),

    columnHelper.display({
      id: 'actions',
      cell: (info) => {
        const {
          isOpen: isCancelOpen,
          onOpen: onCancelOpen,
          onClose: onCancelClose,
        } = useDisclosure();
        const toast = usePHToast();
        const bookingID = info.row.original.bookingID;

        const {
          onClose: onRescheduleClose,
          onOpen: onRescheduleOpen,
          isOpen: isRescheduleOpen,
        } = useDisclosure();

        const handleCancel = async () => {
          setLoading(true);
          try {
            await cancelEvent(bookingID);
            toast({
              status: 'success',
              description: 'Reserva cancelada com sucesso!',
            });
            realoadGet();
          } catch {
            toast({
              status: 'error',
              description: 'Erro ao cancelar a reserva!',
            });
          } finally {
            setLoading(false);
            onCancelClose();
          }
        };

        const handleRescheduleBooking = () => {
          navigate(`/eventos/reagendar/${bookingID}`);
        };

        return (
          <HStack spacing={4} pl={6}>
            <Spacer borderRight="2px dashed #B0CA0A" />
            <Box
              justifyContent="center"
              as="button"
              _hover={{ color: '#B0CA0A' }}
              onClick={() => {
                navigate(`/admin/ingressos/editar/${bookingID}`);
              }}
            >
              <Icon as={FaEdit} w="20px" h="20px" />
              <Text>Editar</Text>
            </Box>
            <Box
              justifyContent="center"
              as="button"
              _hover={{ color: '#B0CA0A' }}
              onClick={() => {
                onRescheduleOpen();
              }}
            >
              <Icon as={TimeIcon} w="20px" h="20px" />
              <Text>Reagendar</Text>
            </Box>
            <Box
              justifyContent="center"
              as="button"
              _hover={{ color: '#B0CA0A' }}
              onClick={onCancelOpen}
            >
              <Icon as={FaRegWindowClose} w="20px" h="20px" />
              <Text>Cancelar</Text>
            </Box>
            <ErrorModal
              isOpen={isCancelOpen}
              onClose={onCancelClose}
              loading={loading}
              content={'DESEJA CANCELAR A RESERVA?'}
              handleMainClick={handleCancel}
            />
            <PophausModal
              icon={BsAlarm}
              isOpen={isRescheduleOpen}
              onClose={onRescheduleClose}
              content="Deseja reagendar?"
              handleMainClick={handleRescheduleBooking}
              loading={loading}
              secondaryButtonText="voltar"
              handleSecondaryClick={onRescheduleClose}
            />
          </HStack>
        );
      },
    }),
  ];

  return columns;
};
