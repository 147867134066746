import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import Form from 'src/components/Form/Form';
import { FormInput } from 'src/components/Form/Input';
import usePHToast from 'src/hooks/useToast';
import { useGetBusinessUnits } from 'src/api/services/businessUnit';
import { FormSelect } from 'src/components/Form/Select';
import { FormSwitch } from 'src/components/Form/Switch';
import { weekDays } from 'src/utils/weekDays';
import { TimeIcon } from '@chakra-ui/icons';
import { useGetCouponByID } from 'src/api/services/coupon';
import { useParams } from 'react-router-dom';
import { Loading } from 'src/components/Loading';
import dayjs from 'dayjs';

export interface CreateCouponFormValues {
  description: string;
  usageLimit: number;
  isActive: boolean;
  singleUse: boolean;
  businessUnitID: number;
  value: number;
  maximumValue: string;
  couponTypeID: number;
  ticketStartDate: number;
  ticketStartTime: string;
  ticketEndDate: number;
  ticketEndTime: string;
  applicationStartDate: number;
  applicationStartTime: string;
  applicationEndDate: number;
  applicationEndTime: string;
  lifeSpanStartDate: string;
  lifeSpanEndDate: string;
  applicableUntil: number;
  intDurationsOnly: boolean;
  bookingMinimumValue: number;
  singleBookingType: boolean;
  roomBookingType: boolean;
}

interface CreateCouponFormProps {
  rhfForm: UseFormReturn<CreateCouponFormValues, any>;
  isEdit?: boolean;
  submitControl?: boolean;
  couponTypeID?: number;
  maxValue?: string;
  loading?: boolean;
}

export default function CreateCouponForm({
  rhfForm,
  isEdit = false,
  submitControl,
  couponTypeID,
  maxValue,
  loading,
}: CreateCouponFormProps) {
  const {
    register,
    setValue,
    formState: { errors },
  } = rhfForm;

  const { data: businessUnits } = useGetBusinessUnits();
  const [couponType, setCouponType] = useState(couponTypeID ? couponTypeID : 2);
  const [maximumValue, setMaximumValue] = useState<string | undefined>(
    maxValue ? maxValue : undefined
  );

  console.log(rhfForm);

  return (
    <Form>
      {loading ? (
        <Loading />
      ) : (
        <Stack mb={5} direction={'column'} spacing={4}>
          <Stack direction={['column', 'row']}>
            <FormInput
              rhfRegister={{
                ...register('description', {
                  required: 'Campo obrigatório',
                }),
              }}
              errorState={errors.description}
              label={'cupom'}
              inputProps={{ placeholder: 'NOME DO CUPOM' }}
            />

            <FormSelect
              label="unidade"
              rhfRegister={{
                ...register('businessUnitID', {
                  required: 'Campo obrigatório',
                }),
              }}
              errorState={errors.businessUnitID}
              selectProps={{ placeholder: 'SELECIONE A UNIDADE' }}
            >
              {businessUnits?.map((b) => (
                <option key={b.businessUnitID} value={b.businessUnitID}>
                  {b.description}
                </option>
              ))}
            </FormSelect>

            <FormSwitch
              label="status"
              rhfRegister={{
                ...register('isActive'),
              }}
            />
          </Stack>

          <Stack direction={['column', 'row']}>
            <FormSelect
              label="Dia dos ingressos - início"
              rhfRegister={{
                ...register('ticketStartDate', {
                  required: 'Campo obrigatório',
                  valueAsNumber: true,
                }),
              }}
              errorState={errors.ticketStartDate}
            >
              {weekDays.map((d) => (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              ))}
            </FormSelect>

            <FormSelect
              label="Dia dos ingressos - fim"
              rhfRegister={{
                ...register('ticketEndDate', {
                  required: 'Campo obrigatório',
                  valueAsNumber: true,
                }),
              }}
              errorState={errors.ticketEndDate}
            >
              {weekDays.map((d) => (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              ))}
            </FormSelect>
          </Stack>

          <Stack direction={['column', 'row']}>
            <FormInput
              label="Horário dos ingressos - início"
              rhfRegister={{
                ...register('ticketStartTime', {
                  required: 'Campo obrigatório',
                }),
              }}
              errorState={errors.ticketStartTime}
              icon={TimeIcon}
              inputProps={{ type: 'time' }}
            />

            <FormInput
              label="Horário dos ingressos - fim"
              rhfRegister={{
                ...register('ticketEndTime', {
                  required: 'Campo obrigatório',
                }),
              }}
              errorState={errors.ticketEndTime}
              icon={TimeIcon}
              inputProps={{ type: 'time' }}
            />
          </Stack>

          <Stack direction={['column', 'row']}>
            <FormSelect
              label="Dia de aplicação do cupom - início"
              rhfRegister={{
                ...register('applicationStartDate', {
                  required: 'Campo obrigatório',
                  valueAsNumber: true,
                }),
              }}
              errorState={errors.applicationStartDate}
            >
              {weekDays.map((d) => (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              ))}
            </FormSelect>

            <FormSelect
              label="Dia de aplicação do cupom - fim"
              rhfRegister={{
                ...register('applicationEndDate', {
                  required: 'Campo obrigatório',
                  valueAsNumber: true,
                }),
              }}
              errorState={errors.applicationEndDate}
            >
              {weekDays.map((d) => (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              ))}
            </FormSelect>
          </Stack>

          <Stack direction={['column', 'row']}>
            <FormInput
              label="Horário de aplicação do cupom - início"
              rhfRegister={{
                ...register('applicationStartTime', {
                  required: 'Campo obrigatório',
                }),
              }}
              errorState={errors.applicationStartTime}
              icon={TimeIcon}
              inputProps={{ type: 'time' }}
            />

            <FormInput
              label="Horário de aplicação do cupom - fim"
              rhfRegister={{
                ...register('applicationEndTime', {
                  required: 'Campo obrigatório',
                }),
              }}
              errorState={errors.applicationEndTime}
              icon={TimeIcon}
              inputProps={{ type: 'time' }}
            />
          </Stack>

          <Stack direction={['column', 'row']}>
            <FormSwitch
              label="1 uso por cpf"
              rhfRegister={{
                ...register('singleUse'),
              }}
            />

            <FormInput
              label="Limite de uso"
              rhfRegister={{
                ...register('usageLimit', {
                  required: 'Campo obrigatório',
                  pattern: {
                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    message: 'Deve ser um número',
                  },
                }),
              }}
              errorState={errors.usageLimit}
              inputProps={{ type: 'number' }}
            />
          </Stack>

          <Stack direction={['column', 'row']}>
            <FormInput
              rhfRegister={{
                ...register('lifeSpanStartDate', {
                  required: 'Campo obrigatório',
                }),
              }}
              label="Data inicial"
              errorState={errors.lifeSpanStartDate}
              inputProps={{ placeholder: 'DATA', type: 'date' }}
            />
            <FormInput
              rhfRegister={{
                ...register('lifeSpanEndDate', {
                  required: 'Campo obrigatório',
                }),
              }}
              label="Data final"
              errorState={errors.lifeSpanEndDate}
              inputProps={{ placeholder: 'DATA', type: 'date' }}
            />
          </Stack>

          <Stack direction={['column', 'row']}>
            <FormSelect
              label="Tipo de desconto"
              rhfRegister={{
                ...register('couponTypeID', {
                  required: 'Campo obrigatório',
                  valueAsNumber: true,
                  value: couponType,
                }),
              }}
              onChange={(v: any) => setCouponType(v.target.value)}
            >
              <option value={2}>Valor fixo</option>
              <option value={1}>Porcentagem</option>
            </FormSelect>

            {couponType == 1 ? (
              <FormInput
                label={'% do desconto'}
                rhfRegister={{
                  ...register('value', { required: 'Campo obrigatório' }),
                }}
                errorState={errors.value}
                inputProps={{ type: 'number' }}
              />
            ) : (
              <FormInput
                label={'Valor do desconto'}
                rhfRegister={{
                  ...register('value', { required: 'Campo obrigatório' }),
                }}
                errorState={errors.value}
                inputProps={{ type: 'number' }}
              />
            )}

            {couponType == 1 && (
              <FormInput
                label={'Valor máximo do desconto'}
                rhfRegister={{
                  ...register('maximumValue', {
                    value: maximumValue,
                  }),
                }}
                errorState={errors.maximumValue}
                inputProps={{
                  type: 'number',
                  onChange: (v) => setMaximumValue(v.target.value),
                }}
              />
            )}
          </Stack>

          <Stack direction={['column', 'row']}>
            <FormInput
              label={'Limite de dias para uso dos ingressos após data final'}
              rhfRegister={{
                ...register('applicableUntil', {
                  required: 'Campo obrigatório',
                }),
              }}
              errorState={errors.applicableUntil}
              inputProps={{ type: 'number' }}
            />
            <FormSwitch
              label="Válido para reservas de ingresso avulso"
              rhfRegister={{
                ...register('singleBookingType'),
              }}
            />
          </Stack>

          <Stack direction={['column', 'row']}>
            <FormSwitch
              label="Válido para reservas de sala"
              rhfRegister={{
                ...register('roomBookingType'),
              }}
            />
            <FormInput
              label="Valor mínimo da reserva"
              rhfRegister={{
                ...register('bookingMinimumValue', {
                  required: 'Campo obrigatório',
                }),
              }}
              errorState={errors.bookingMinimumValue}
              inputProps={{ type: 'number', min: 0 }}
            />
          </Stack>
        </Stack>
      )}
    </Form>
  );
}
