import React from 'react';
import {
  Stack,
  Text,
  Container,
  TextProps,
  ContainerProps,
  Link,
} from '@chakra-ui/react';

interface ContactProps {
  textProps?: TextProps;
  containerProps?: ContainerProps;
}

export default function Contact({ textProps, containerProps }: ContactProps) {
  return (
    <Container
      as={Stack}
      maxW={'25ch'}
      direction="column"
      w="auto"
      {...containerProps}
    >
      <Text
        color="green"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="xs"
        {...textProps}
      >
        CONTATO:
      </Text>
      <Text
       lineHeight="2"
        color="white"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="xs"
        {...textProps}
      >
        {/* CONTATO@ARCADEHAUS.COM
        <br />
        <br />
        Contato Telefone:
        <br />
        +55 11 4570-0061
        <br />
        <br /> */}
        Contato WhatsApp:
        <br />
        <Link href="https://wa.me/5511941726988" target="_blank">
          +55 (11) 91770-6110
        </Link>
      </Text>
      <Text
        color="green"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="xs"
        {...textProps}
      >
        IMPRENSA:
      </Text>
      <Text
        color="white"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="xs"
        {...textProps}
      >
        imprensa@pophaus.com.br
      </Text>
      <Text
        color="green"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="xs"
        {...textProps}
      >
        CNPJ:
      </Text>
      <Text
        color="white"
        fontWeight="semibold"
        textTransform={'uppercase'}
        fontSize="xs"
        {...textProps}
      >
        32.305.603/0001-98
      </Text>
    </Container>
  );
}
