import { Box, VStack } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FinancialFiltersForm, {
  FinancialFiltersFormValues,
} from 'src/modules/Forms/Backoffice/FinancialFiltersForm';
import PageContainer from '../../../components/Container/PageContainer';
import InfoDashboard from './InfoDashboard';
import { useGetFinancialReport } from 'src/api/services/financialReport';
import usePHToast from 'src/hooks/useToast';
import { FinancialReportResult } from 'src/api/interfaces/financialReport';
import { PaymentGroupResult } from 'src/api/interfaces/paymentGroupResult';
import { SalesPerDurationResult } from 'src/api/interfaces/salesPerDurationResult';

export default function AdminFinancial() {
  const form = useForm<FinancialFiltersFormValues>();
  const toast = usePHToast();

  const [financialReport, setFinancialReport] =
    useState<FinancialReportResult | null>(null);
  const [paymentGroups, setPaymentGroups] = useState<
    PaymentGroupResult[] | null
  >(null);
  const [salesPerDuration, setSalesPerDuration] = useState<
    SalesPerDurationResult[] | null
  >(null);
  const [reportInterval, setReportInterval] = useState<string[]>([]);

  const handleFinancialForm = async (
    businessUnitID: number,
    startDate: string,
    endDate: string
  ) => {
    try {
      const report = await useGetFinancialReport(
        businessUnitID,
        startDate,
        endDate
      );
      setFinancialReport(report);
      setPaymentGroups(report?.paymentGroup);
      setSalesPerDuration(report?.salesPerDuration);
      setReportInterval([startDate, endDate]);
      
      if (report === null) {
        toast({ status: 'error', description: 'Não há Registro!' });
      }
    } catch (e: any) {
      toast({ status: 'error', description: e?.message });
    }
  };

  return (
    <>
      <PageContainer restricted>
        <VStack w="full" spacing={2} align="center" pt={12}>
          <FinancialFiltersForm
            form={form}
            handleFinancialForm={handleFinancialForm}
          />
          {financialReport != null ? (
            <InfoDashboard
              report={financialReport}
              paymentGroups={paymentGroups}
              salesPerDuration={salesPerDuration}
              reportInterval={reportInterval}
            />
          ) : null}
        </VStack>
      </PageContainer>
    </>
  );
}
